
import React, { useRef } from "react";

import DragAndDrop from "../../components/dnd/index"
import Navbar from "../../components/navbar/index";
import styles from "./page.module.css";
import LoadingBar from "react-top-loading-bar";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Page(props) {
  const loadingRef = useRef(null)
  let navigate = useNavigate()
  const profileDetails = useSelector(state => state.ProfileDetailsReduxState)
  
return (
    profileDetails !== "" ?
    <Navigate to="/jis"/>
      :
      <div>
        <LoadingBar color="#0d6efd" ref={loadingRef} />
        <Navbar handleLogout={props.handleLogout}>
          <div className="wrapper-ater-log-in">
            <DragAndDrop loadingRef={loadingRef} />
          </div>
        </Navbar>
      </div>
)
}
