import styles from "./page.module.css";
import AdminNavbar from "../../components/admin-navbar/index";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ADMIN_FEEDBACK_LIST } from "../../config";
import { convertUtcToLocal } from "../../utils/helper";

// ... imports

export default function Admin(props) {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loadingFeedbacks, setLoadingFeedbacks] = useState(false);
  const [expandedMessages, setExpandedMessages] = useState({});
  const [showMoreDisplay,setShowMoreDisplay]=useState({})

  useEffect(() => {
    setLoadingFeedbacks(true);
    axios
      .get(ADMIN_FEEDBACK_LIST,{withCredentials:true})
      .then((res) => {
        console.log(res)
        setFeedbacks(res?.data || []);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingFeedbacks(false);
      });
  }, []);

  const toggleMessageExpansion = (id) => {
    setExpandedMessages((prevExpanded) => ({
      ...prevExpanded,
      [id]: !prevExpanded[id],
    }));
  };
  const cardRef = useRef(null);

  // const calculateLines = (_id) => {
  //   const cardElement = cardRef.current;
  //   console.log("card elemeent", cardElement)
  //   if (cardElement) {
  //     const lineHeight = parseFloat(window.getComputedStyle(cardElement).lineHeight);
  //     const totalHeight = parseFloat(document.getElementById(_id).offsetHeight); 

  //     const approximateLines = Math.ceil(totalHeight / lineHeight);
  //     console.log(approximateLines)
  //     return approximateLines;
  //   }
  //   return 0;
  // };
useEffect(()=>{
  const cardElement = cardRef.current;

  let approximateLines={}
  if(cardElement){
    const lineHeight = parseFloat(window.getComputedStyle(cardElement).lineHeight);
    for(let feedback of feedbacks){
      let divTotalHeight=parseFloat(document.getElementById(feedback["_id"]).offsetHeight)

      approximateLines[feedback["_id"]]=Math.ceil(divTotalHeight / lineHeight);



    }
    setShowMoreDisplay(approximateLines)
  }
 
},[feedbacks])

  return  (
    <div>
    {console.log("show more==>",showMoreDisplay)}
      <AdminNavbar handleLogoutAdmin={props.handleLogoutAdmin}>
        <div style={{ margin: "1rem" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <h1>List of Feedbacks</h1>
            {loadingFeedbacks && (
              <div className="spinner" style={{ height: "25px", width: "25px" }}></div>
            )}
          </div>
          {feedbacks.map(({ _id, message, createdAt }) => (
            <div key={_id} className={styles.feedbackCard}>
              <div className={styles.feedback}>
                <div
                  id={_id}
                  ref={cardRef}
                  className={`${styles.message} ${
                    expandedMessages[_id] ? styles.expandedMessage : styles.collapsedMessage
                  }`}
                >
                  {message}
                </div>
                {showMoreDisplay[_id] >= 3 && ( // Show "See more" or "See less" only when lines are clamped
                  <p style={{ color: "blue", cursor: "pointer" }} onClick={() => toggleMessageExpansion(_id)}>
                    {expandedMessages[_id] ? "See less" : "See more"}
                  </p>
                )}
              </div>
              <div className={styles.feedbackTime}>
                {convertUtcToLocal(createdAt)?.substring(0, 17)}
              </div>
            </div>
          ))}
        </div>
      </AdminNavbar>
    </div>
  );
}

