// export const BASE_URL=process.env.REACT_APP_BASE_URL
// export const BASE_URL="http://localhost:5000"
export const BASE_URL=process.env.REACT_APP_ENVIRONMENT==='development'?"http://localhost:5000":process.env.REACT_APP_BASE_URL


export const LOGIN_URL = BASE_URL + "/api/v1/resume_sort_app/auth/login"
export const CREATE_PROJECT_URL = BASE_URL + "/api/v1/resume_sort_app/project/create"
export const GET_ALL_PROJECTS_URL = BASE_URL + "/api/v1/resume_sort_app/projects"
export const DELETE_PROJECT_URL = BASE_URL + "/api/v1/resume_sort_app/project/"
export const UPLOAD_RESUME = BASE_URL + "/api/v1/resume_sort_app/dashboard/upload_and_extract"
export const DOWNLOAD_RESUME = BASE_URL + "/api/v1/resume_sort_app/dashboard/download_pdf"
export const GET_RESUME_DATA = BASE_URL + "/api/v1/resume_sort_app/dashboard/resumes_data"
export const GET_PROFILE_IMAGE = BASE_URL + "/api/v1/resume_sort_app/dashboard/profile_image"
export const VERIFY_COOKIE = BASE_URL + "/api/v1/resume_sort_app/auth/verify"
export const LOGOUT_URL = BASE_URL + "/api/v1/resume_sort_app/auth/logout"
export const ADMIN_LOGOUT_URL = BASE_URL + "/api/v1/resume_sort_app/auth/admin/logout"

export const JIS_PDF_URL = BASE_URL + "/api/v1/resume_sort_app/dashboard/jis_pdf"
export const DELETE_RESUME_DATA=BASE_URL+"/api/v1/resume_sort_app/resume/delete"
export const SEND_VERFIFCATION_CODE=BASE_URL+"/api/v1/resume_sort_app/auth/register/send_verification_code"
export const VERIFY_CODE=BASE_URL+"/api/v1/resume_sort_app/auth/register/verify_code"
export const CREATE_ACCOUNT=BASE_URL+"/api/v1/resume_sort_app/auth/register/create_account"
export const UPDATE_USER=BASE_URL+"/api/v1/resume_sort_app/resume/update_resume_user"
export const FEEDBACK_SUBMIT=BASE_URL+"/api/v1/resume_sort_app/feedback"
export const ADMIN_SIGN_IN=BASE_URL+"/api/v1/resume_sort_app/admin/sign-in"
export const ADMIN_USERS_LIST=BASE_URL+"/api/v1/resume_sort_app/admin/users_list"
export const ADMIN_FEEDBACK_LIST=BASE_URL+"/api/v1/resume_sort_app/admin/feedback_list"