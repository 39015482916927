import Jis from "../../components/jis";
import Navbar from "../../components/navbar/index"
import styles from "./page.module.css";
export default function JIS(props) {
  return (
    <Navbar handleLogout={props.handleLogout}>
    
        <Jis />
   
    </Navbar>
  );
}
