import React, { useRef, useState } from 'react';

import styles from "./page.module.css";
import { Link } from 'react-router-dom';

import Logo from "../../static/images/logo.svg"
import Demo from "../../static/images/demo.svg"
import TabImage from "../../static/images/tabs.svg"
import Globe from "../../static/images/globe.svg"
import Clock from "../../static/images/clock.svg"
import Share from "../../static/images/share.svg"
import AccArrow from "../../static/images/acc-arrow.svg"
import Arrow from "../../static/images/arrow-down.svg";
import DemoGIFEn from "../../static/images/demo_english_subtitle.gif"
import DemoGIFJa from "../../static/images/demo_japanese_subtitle.gif"
import DragAndDrop from "../../components/dnd/index"
import LoadingBar from 'react-top-loading-bar';
import i18next from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changeLanguageAction } from '../../redux/actions';
import axios from 'axios';
import { FEEDBACK_SUBMIT } from '../../config';


const LandingPage = () => {
  const loadingRef = useRef(null)
  const [feedback, setFeedback] = useState("")
  const [loadingFeedBackSubmit, setLoadingFeedBackSubmit] = useState(false)
  const [acc, setAcc] = useState(false);
  const [tabOne, setTabOne] = useState(0);
  const [tabTwo, setTabTwo] = useState(0);
  const [tabThree, setTabThree] = useState(0);
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [language, setLanguage] = useState("日本語")
  function languageChange() {

    if (language === "日本語") {
      console.log("Japnese clicked")

      setLanguage("English")
      i18next.changeLanguage("en")
      dispatch(changeLanguageAction("en"))
    } else {
      console.log("English clicked")
      setLanguage("日本語")
      i18next.changeLanguage("ja")
      dispatch(changeLanguageAction("ja"))
    }
  }
  function handleFeedbackSubmit() {
    console.log(feedback)
    if (feedback === "") {
      alert(t("Feedback should not be empty"))
    } else {


      const utctime = new Date().toISOString()
      const formData = new FormData()
      formData.append("feedback", feedback)
      formData.append("utctime", utctime)
      setLoadingFeedBackSubmit(true)
      axios.post(FEEDBACK_SUBMIT, formData).then(res => {
        console.log(res)
        setFeedback("")
        setLoadingFeedBackSubmit(false)
      }).catch(err => {
        console.log(err)
        alert(err?.response?.data?.message)
        setFeedback("")
        setLoadingFeedBackSubmit(false)
      })
    }

  }

  return (
    <section>
      <div className={styles.navbar}>
        <LoadingBar color="#0d6efd" ref={loadingRef} />
        <img src={Logo} alt="logo" width={75} height={47} />
        <div className={styles.navLinks}>
          {/* <Link onClick={() => document.getElementById('demo').scrollIntoView({ behavior: 'smooth' })}>{t("Demo")}</Link>
          <Link onClick={() => document.getElementById('tutorial').scrollIntoView({ behavior: 'smooth' })}>{t("Tutorial")}</Link>
          <Link onClick={() => document.getElementById('why').scrollIntoView({ behavior: 'smooth' })}>{t("Why we built this?")}</Link>
          <Link onClick={() => document.getElementById('features').scrollIntoView({ behavior: 'smooth' })}>{t("Features")}</Link> */}
          {/* <div className={styles.dropdown}>
            <button className={styles.dropbtn}>
              <img src={Globe} alt="globe" />
              {language === "日本語" ? "English" : "日本語"}
              <img src={Arrow} alt="arrow" style={{ marginLeft: '5px' }} />
            </button>
            <div className={styles.dropdownContent}>
              <button onClick={() => languageChange('ja')}>日本語</button>
              <button onClick={() => languageChange('en')}>English</button>
            </div>
          </div> */}
        </div>
        <div className={styles.LangAndSignInWrapper}>
          <div onClick={languageChange} className={styles.dropdown}>
            <button className={styles.dropbtn}>
              <img src={Globe} alt="globe" />
              {language === "日本語" ? <span>English</span> : <span>日本語</span>}
              {/* <img src={Arrow} alt="arrow" style={{ marginLeft: '5px' }} /> */}
            </button>
            {/* <div className={styles.dropdownContent}>
              <button onClick={() => languageChange('ja')}>日本語</button>
              <button onClick={() => languageChange('en')}>English</button>
            </div> */}
          </div>
          <Link to="/sign-in">
            <button className={styles.getStartedBtn}> {t("Get Started")} </button>
          </Link>
        </div>
      </div>

      <div id='demo'>
        <DragAndDrop page="lp" loadingRef={loadingRef} />
      </div>

      <div className={styles.heading}>
        {t("How to convert English resume to Japanese resume (JIS standard)")}
      </div>
      <div className={styles.subHeading}>
        {t("You can convert your English resume to Japanese resume (JIS standard) by following the simple steps below.")}
      </div>
      <div className={styles.tutorial} id='tutorial'>
        <img src={language === "English" ? DemoGIFEn : DemoGIFJa} alt="tutorial" className={styles.tutorialImg} />
        <div>
          <div className={styles.steps}>
            <div className={styles.stepNumber}>
              1.
            </div>
            <div className={styles.stepText}>
              <Trans

                i18nKey="myKey"
                defaults={t("Click the Select File button above or drag and drop the file into the drop range.")} // optional defaultValue
              // components={{ bold: <strong /> }}
              />
              {/* {t("Simply <span>drag and drop</span> the candidate&apos;s resume into the upload box, or select the file manually")}. */}
            </div>
          </div>

          <div className={styles.steps}>
            <div className={styles.stepNumber}>
              2.
            </div>
            <div className={styles.stepText}>
              <Trans

                i18nKey="myKey2"
                defaults={t("Select the English resume PDF that you want to convert to Japanese format.")} // optional defaultValue
              // components={{ bold: <strong /> }}
              />

            </div>
          </div>

          <div className={`${styles.steps} ${styles.lastStep}`}>
            <div className={styles.stepNumber}>
              3.
            </div>
            <div className={styles.stepText}>
              <Trans

                i18nKey="myKey2"
                defaults={t("Automatically converts the file from <strong>English resume to Japanese resume</strong> (JIS standard) in about 30 seconds.")} // optional defaultValue
                components={{ bold: <strong /> }}
              />
            </div>
          </div>

          <div className={`${styles.steps} ${styles.lastStep}`}>
            <div className={styles.stepNumber}>
              4.
            </div>
            <div className={styles.stepText}>
              <Trans

                i18nKey="myKey2"
                defaults={t("A <strong>preview</strong> of the original resume and the converted resume will be displayed.")} // optional defaultValue
                components={{ bold: <strong /> }}
              />
            </div>
          </div>

          <div className={`${styles.steps} ${styles.lastStep}`}>
            <div className={styles.stepNumber}>
              5.
            </div>
            <div className={styles.stepText}>
              <Trans

                i18nKey="myKey2"
                defaults={t("You can <strong>download</strong> the converted resume by logging in.")} // optional defaultValue
                components={{ bold: <strong /> }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.featuresWrapper}>
        <div className={styles.feature}>
          <div>
            <img src={Clock} alt='Clock' />
          </div>
          <div className={styles.featuresHeading}>
            {t("Converts quickly in about 30 seconds")}
          </div>
          <div className={styles.featureContent}>
            {t("With the Resume Translation & JIS Conversion app, you can convert files in about 30 seconds.")}
          </div>
        </div>

        <div className={styles.feature}>
          <div>
            <img src={Share} alt='Share' />
          </div>
          <div className={styles.featuresHeading}>
            {t("You can download the converted PDF")}
          </div>
          <div className={styles.featureContent}>
            {t("You can download the PDF converted by the Resume Translation & JIS Conversion app.")}
          </div>
        </div>
      </div>

      <div className={styles.heading}>
        {t("FAQ")}
      </div>
      <div className={styles.accWrapper}>
        <button className={styles.accHeader} onClick={() => { acc ? setAcc(false) : setAcc(true) }}>
          {t("Are the converted files safe?")}
          <img src={AccArrow} alt='Arrow' />
        </button>
        {acc ? <div className={styles.accContent}>
          {t("Yes, the converted Japanese resume (JIS standard) is safe. CosBE has security and privacy measures in place so you can convert your files with confidence.")}
        </div> : null}
      </div>

      <div className={styles.heading}>
        {t("Please give us your feedback")}
      </div>
      <div>
        <textarea className={styles.comment} value={feedback} onChange={(e) => setFeedback(e.target.value)} placeholder={t("Please leave your feedback here...")}>
        </textarea>
        {/* <button className={styles.commentBtn} onClick={handleFeedbackSubmit}>{t("send")}</button> */}
        <button disabled={loadingFeedBackSubmit} className={styles.commentBtn} style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }} onClick={handleFeedbackSubmit}>{t("send")}
          {loadingFeedBackSubmit && <div className="spinner" style={{ height: "15px", width: "15px" }}></div>}</button>

      </div>



      <div className={styles.cta}>
        <div className={styles.ctaText}>
          <Trans

            i18nKey="myKey3"
            defaults={t("Start building your team <bold>today!</bold>")} // optional defaultValue
            components={{ bold: <strong /> }}
          />

        </div>
        <Link to="/sign-in">
          <button className={styles.ctaBtn}>{t("Begin Now")}</button>
        </Link>
      </div>

      <div className={styles.footer}>
        <div className={styles.footerImg}>
          <img src={Logo} alt="logo" width={116} height={73} />
        </div>
        <div className={styles.footerContent}>
          <div>
            <div className={styles.footerContentTitle}>products</div>
            <Link to={'https://www.v2.resume-sort-app.cosbe.inc/'}>Resume Screening</Link>
            <Link to="https://www.cosbe.inc/call-cosbe">Call CosBE</Link>
          </div>
          <div>
            <div className={styles.footerContentTitle}>Services</div>
            <Link to="https://www.cosbe.inc/solutions">CosBE Pro-Service</Link>
            <Link to="https://www.cosbe.inc/call-cosbe">CosBE SaaS</Link>
            <Link to="https://cosbetrialschool-english.my.canva.site/">CosBE School</Link>
          </div>
          <div>
            <div className={styles.footerContentTitle}>Info</div>
            <Link to="https://www.cosbe.inc/about">About CosBE</Link>
            <Link to="https://ws.formzu.net/fgen/S208480895/">Contact Us</Link>
          </div>
          <div>
            <div className={styles.footerContentTitle}>Terms</div>
            <Link to="/privacy-policy" >Privacy Policy</Link>
            {/* <Link to="">Terms & Conditions</Link> */}
          </div>
        </div>
      </div>
      <div className={styles.footerFooter}>
        <div className={styles.dropdown}>
          <div onClick={languageChange} className={styles.dropdown}>
            <button className={styles.dropbtn}>
              <img src={Globe} alt="globe" />
              {language === "日本語" ? <span>English</span> : <span>日本語</span>}
              {/* <img src={Arrow} alt="arrow" style={{ marginLeft: '5px' }} /> */}
            </button>
            {/* <div className={styles.dropdownContent}>
              <button onClick={() => languageChange('ja')}>日本語</button>
              <button onClick={() => languageChange('en')}>English</button>
            </div> */}
          </div>
          {/* <button className={styles.dropbtn}>
            <img src={Globe} alt="globe" />
            
            <img src={Arrow} alt="arrow" style={{ marginLeft: '5px' }} />
            {language}
          </button> */}
          {/* <div className={styles.dropdownContent}>
            <button onClick={() => languageChange('ja')}>日本語</button>
            <button onClick={() => languageChange('en')}>English</button>
          </div> */}
        </div>
        <div className={styles.copyright}>Copyright © {new Date().getFullYear()} CosBE</div>
      </div>
    </section>
  )
}

export default LandingPage