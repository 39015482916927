import { combineReducers } from "@reduxjs/toolkit"

const initialState={
    loginEmail:'',
    registerdEmail:'',
    projectDetails:'',
    profileDetails:'',
    language:'ja',
    userID:''
}
const LoginEmailReducer = (state = initialState.loginEmail, action) => {
    switch (action.type) {
        case 'LoginEmail':
            return action.payload
        default:
            return state
    }
}
const RegisterdEmailReducer = (state = initialState.registerdEmail, action) => {
    switch (action.type) {
        case 'RegisterdEmail':
            return action.payload
        default:
            return state
    }
}
const UserIdReducer = (state = initialState.userID, action) => {
    switch (action.type) {
        case 'UserID':
            return action.payload
        default:
            return state
    }
}
const ProjectDetailsReducer = (state = initialState.projectDetails, action) => {
    switch (action.type) {
        case 'ProjectDetails':
            return action.payload
        default:
            return state
    }
}
const ProfileDetailsReducer = (state = initialState.profileDetails, action) => {
    switch (action.type) {
        case 'ProfileDetails':
            return action.payload
        default:
            return state
    }
}
const LanguageChangeReducer = (state = initialState.language, action) => {
    switch (action.type) {
        case 'LANGUAGE_CHANGE':
            return action.payload
        default:
            return state
    }
}

const rootReducer = combineReducers({
    LoginEmailReduxState: LoginEmailReducer,
    RegisterdEmailReduxState: RegisterdEmailReducer,
    ProjectDetailsReduxState: ProjectDetailsReducer,
    ProfileDetailsReduxState:ProfileDetailsReducer,
    LanguageChangeReduxState:LanguageChangeReducer,
    UserIdReduxState:UserIdReducer

})
export default rootReducer