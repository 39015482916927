import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import styles from "./comp.module.css";
import axios from 'axios';
import { DOWNLOAD_RESUME, JIS_PDF_URL, UPDATE_USER } from '../../config';
import { Button } from 'bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { profileDetailsAction } from '../../redux/actions';


const Jis = (props) => {
    const location = useLocation();
    const pathname = location.pathname;
    const profile_details = useSelector(state => state.ProfileDetailsReduxState)
    const UserIdReduxState = useSelector(state => state.UserIdReduxState)
    const dispatch = useDispatch()

    let original_resume_url = ""
    let jis_resume_url = ""
    const { t } = useTranslation()


    if (pathname === "/upload") {


        const { state } = useLocation
        original_resume_url = DOWNLOAD_RESUME + "?url=" + state.pdf_path
        jis_resume_url = JIS_PDF_URL + "?resume-id=" + profile_details._id

    } else {
        original_resume_url = DOWNLOAD_RESUME + "?url=" + profile_details?.pdf_url?.split("s3.amazonaws.com/")[1]
        jis_resume_url = JIS_PDF_URL + "?resume-id=" + profile_details?._id
    }
    const [loadingJIS, setLoadingJIS] = useState(true);
    const [loadingOriginal, setLoadingOriginal] = useState(true);
    const [pdfFile, setPdfFile] = useState(null);
    const [jisFile, setJisFile] = useState(null);


    useState(async () => {
        setLoadingOriginal(true)
        const formdata = new FormData()
        if(pathname === "/upload"){
            formdata.append("page","LandingPage")
        }else{
            formdata.append("page","UploadPage")
        }

        try {
            const response = await axios.post(original_resume_url,formdata, {
                responseType: 'blob',
                headers: {
                    Accept: "application/pdf"
                },
                withCredentials:true // Set responseType to 'blob' to handle binary data
            });

            // Create a blob URL from the received blob data
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const pdfBlobUrl = URL.createObjectURL(blob);

            // Set the PDF blob URL to the state
            setPdfFile(pdfBlobUrl);
        } catch (error) {
            console.error('Error fetching PDF file:', error);
        } finally {
            setLoadingOriginal(false); // Set loading to false after fetching completes (or encounters an error)
        }
    }, [])
    useState(async () => {
        setLoadingJIS(true)
        try {
            console.log("condititon---->", profile_details?._id === profile_details?.projectID && UserIdReduxState !== "")
            if (profile_details?._id === profile_details?.projectID && UserIdReduxState !== "") {


                const updateUserFormData = new FormData()
                updateUserFormData.append("resume_id", profile_details?._id)
                updateUserFormData.append("user_id", UserIdReduxState)
                if(pathname === "/upload"){
                    updateUserFormData.append("page","LandingPage")
                    // updateUserFormData.append("user_id", UserIdReduxState)
                }
                else{
                    updateUserFormData.append("page","UploadPage")
                }
                await axios.post(UPDATE_USER, updateUserFormData,{withCredentials:true}).then(res => {
                    console.log(res.data)
                    dispatch(profileDetailsAction((res?.data?.data)))
                    // profile_details["projectID"] = UserIdReduxState
                    // console.log("profile-details--->",profile_details)
                    // dispatch(profileDetailsAction(profile_details))
                    // console.log("profile-details after--->",profile_details)
                }).catch(err => {
                    console.log(err)
                })
            }
            const formdata = new FormData()
            if(pathname === "/upload"){
                formdata.append("page","LandingPage")
            }else{
                formdata.append("page","UploadPage")
            }
            const response = await axios.post(jis_resume_url,formdata, {
                responseType: 'blob',
                headers: {
                    Accept: "application/pdf"
                },
                withCredentials:true // Set responseType to 'blob' to handle binary data
            });

            // Create a blob URL from the received blob data
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const pdfBlobUrl = URL.createObjectURL(blob);

            // Set the PDF blob URL to the state
            setJisFile(pdfBlobUrl);
        } catch (error) {
            console.error('Error fetching PDF file:', error);
        } finally {
            setLoadingJIS(false); // Set loading to false after fetching completes (or encounters an error)
        }
    }, [])

    // useState(()=>{
    //  console.log("-----",pathname)   
    //  console.log("-----",profile_details)
    // },[])


    // const handleLoadJIS = () => {
    //     setLoadingJIS(false);
    // };
    // const handleLoadOriginal = () => {
    //     setLoadingOriginal(false);
    // };

    const [signInPopUp, setSignInPopUp] = useState(false)
    function popUpBtn() {
        if (signInPopUp === false) {
            setSignInPopUp(true)
            document.getElementsByTagName('html')[0].style.overflow = "hidden";
        }
        else {
            setSignInPopUp(false)
            document.getElementsByTagName('html')[0].style.overflow = "scroll";
        }
    }

    return (
        <div>
            {pathname === "/" ?
                // <Link to="/sign-in">
                <button
                    // onClick={() => {
                    //     props.setNotScreening(true)
                    //     props.setExtracted(false)
                    //     props.setStart(false)
                    // }}
                    onClick={popUpBtn}
                    className={styles.getStartedBtn}
                > {t("Download PDF")}</button>
                // </Link>

                : <></>}

            {/* <h1>Original Resume V/S JIS Resume</h1> */}

            {
                signInPopUp ?
                    <div className={styles.popUpOverlay}>
                        <div className={styles.popUpWrapper}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={popUpBtn}>
                                <circle cx="15" cy="15" r="14.75" fill="white" stroke="#B9CDDD" stroke-width="0.5" />
                                <path d="M9.05383 21.7665L8 20.6764L13.6 14.8833L8 9.09016L9.05383 8L14.6538 13.7931L20.2538 8L21.3077 9.09016L15.7077 14.8833L21.3077 20.6764L20.2538 21.7665L14.6538 15.9734L9.05383 21.7665Z" fill="#677580" />
                            </svg>

                            <p>{t("Please sign-in or sign-up to download the screened resume.")}</p>
                            <hr></hr>
                            <Link to="/sign-in"><button className={styles.signInBtn}>{t("Continue")}</button></Link>
                        </div>
                    </div>
                    :
                    null
            }

            <div className='mx-10'>
                <div className='row'>
                    <div className='col-sm-6'>
                        {/* {original_resume_url} */}
                        {loadingOriginal && (
                            <div>
                                <p>Loading Original Resume...</p>
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )}
                        {pathname === "/" ?
                            <embed src={` ${pdfFile}#toolbar=0`} type="application/pdf" width="100%" height="850px" />
                            : <embed src={` ${pdfFile}`} type="application/pdf" width="100%" height="850px" />
                        }
                    </div>
                    <div className='col-sm-6'>
                        {loadingJIS && (
                            <div>
                                <p>Loading JIS Resume...</p>
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )}
                        {pathname === "/" ?
                            <embed src={` ${jisFile}#toolbar=0`} type="application/pdf" width="100%" height="850px" />
                            : <embed src={` ${jisFile}`} type="application/pdf" width="100%" height="850px" />

                        }
                    </div>

                </div>

            </div>
            {/* {state?.pdf_path} */}
        </div>
    )
}

export default Jis