
import styles from "./page.module.css";

import Logo from "../../static/images/logo.svg";
import Tick from "../../static/images/feature-tick.svg";
import Google from "../../static/images/google.svg";
import { useRef, useState } from "react";
import LoadingBar from 'react-top-loading-bar'
import axios from "axios";
import { LOGIN_URL } from "../../config";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { LoginEmailAction, UserIdAction, changeLanguageAction } from "../../redux/actions";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link } from 'react-router-dom';


export default function SignIn(props) {
  const loadingRef = useRef(null)
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const profileDetails = useSelector(state => state.ProfileDetailsReduxState)
  const { t } = useTranslation()
  const [credentials, setCredentials] = useState({ email: "", password: "" })
  function handleChange(event) {
    setCredentials({ ...credentials, [event.target.name]: event.target.value });

  }
  function handleLoginSubmit() {

    if (credentials.email === "" || credentials.password === "") {
      alert(t("Email or Password can't be empty"))
    }
    else {
      loadingRef.current.continuousStart();
      axios.post(LOGIN_URL, credentials, {

        withCredentials: true
      })
        .then((res) => {
          console.log(res)
          dispatch(LoginEmailAction(res.data.email))
          dispatch(UserIdAction(res.data._id))
          loadingRef.current.complete()
          props.setAuthenticated(true)
          navigate("/upload");
          // if(profileDetails!==""){
          //   console.log(profileDetails)
          //   navigate("/jis", { state: { pdf_path: profileDetails.pdf_url.split("s3.amazonaws.com/")[1] } })
          // }else{
          //   navigate("/upload");
          // }

        }).catch(err => {
          // alert("Incorrect email or password!")
          console.log(err)
          alert(t(err?.response?.data?.detail))
          loadingRef.current.complete()

        })
    }
  }
  function languageChange(lang) {
    i18next.changeLanguage(lang)

    dispatch(changeLanguageAction(lang))
  }
  return (
    <div>
      <LoadingBar color="#0d6efd" ref={loadingRef} />
      <section className={styles.signIn}>
        <div className={styles.features}>
          <img src={Logo} alt="Logo" style={{ cursor: "pointer" }}
            onClick={() => navigate("/")} className={styles.featuresLogo} />
          <div className={styles.featureTitle}>
            {t("Sign in now to easily find and hire the best candidates")}
          </div>
          <div className={styles.featureItems}>
            <img src={Tick} alt="Tick" />
            {t("Download, upload and screen resumes without limits.")}
          </div>
          <div className={styles.featureItems}>
            <img src={Tick} alt="Tick" />
            {t("Access seamless resume translations and tailored dashboards.")}
          </div>
          {/* <div className={styles.featureItems}>
            <img src={Tick} alt="Tick" />
            {t("Find the perfect candidates with advanced search tools.")}
          </div> */}
          <div className={styles.featureItems}>
            <img src={Tick} alt="Tick" />
            {t("Enjoy secure resume storage and the latest feature updates.")}
          </div>
        </div>

        <div className={styles.singInCard}>
          <div className={styles.langbtn}>
            <span onClick={() => languageChange('ja')} style={{ cursor: "pointer" }}>日本語</span>
            <span style={{ fontWeight: 'bolder', fontSize: "1rem" }}> | </span>
            <span onClick={() => languageChange('en')} style={{ cursor: "pointer" }}>English</span>
          </div>
          {/* <img src={Logo} alt="Logo" style={{ cursor: "pointer" }}
            onClick={() => navigate("/")} className={styles.signInLogo} /> */}
          <div className={styles.signInTitle}>{t("Sign in")}</div>
          <div className={styles.signInSubTitle}>
            {t("New user?")} <br />
            <Link to='/create-account'>
              <button>{t("Create an account")}</button>
            </Link>
          </div>
          <div>
            <label>{t("Email address")}</label>
            <input type="email" name="email" value={credentials.email}
              onChange={handleChange} />

            <label>{t("Password")}</label>
            <input type="password" name="password" value={credentials.password}
              onChange={handleChange} />
            <button className={styles.signInBtn} onClick={handleLoginSubmit}>{t("Login")}</button>

            {/* <Link href={"/upload"} style={{ textDecoration: "none" }}><button className={styles.signInBtn}>Continue</button></Link> */}
          </div>
          {/* <div className={styles.resetPwd}>Reset Password</div> */}
          {/* <div className={styles.orWrapper}>
            <div className={styles.orHR}></div>
            <div>Or</div>
            <div className={styles.orHR}></div>
          </div>
          <button className={styles.signInWithGoogle}>
            <img src={Google} alt="Google" />
            {t("Continue with Google")}
          </button> */}
        </div>

      </section>
    </div>
  );
}
