import styles from "./page.module.css";

import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { RegisterdEmailAction, changeLanguageAction } from "../../redux/actions";
import { useRef, useState } from "react";

import Logo from "../../static/images/logo.svg";
import Tick from "../../static/images/feature-tick.svg";
import SuccessGif from "../../static/images/success.gif"
import { CREATE_ACCOUNT, SEND_VERFIFCATION_CODE, VERIFY_CODE } from "../../config";
import axios from "axios";

export default function CreateAccount() {
    let navigate = useNavigate()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [getEmail, setGetEmail] = useState(true)
    const [register, setRegister] = useState(false)
    const [signUpEmail, setSignUpEmail] = useState("")
    const [credentials, setCredentials] = useState({ email: "", verification_code: "" })
    const [errorMessage, setErrorMessage] = useState("")
    const [emailSend, setEmailSend] = useState(false)
    const [emailVerified, setEmailVerified] = useState(false)
    const [errorMessageForPassword, setErrorMessageForPassword] = useState("")
    const [accountCreated, setAccountCreated] = useState(false)
    const registerdEmail = useSelector(state => state.RegisterdEmailReduxState)

    const [userData, setUserData] = useState({
        email: registerdEmail,
        company_name: "",
        full_name: "",
        job_title: "",
        password: "",
        confirm_password: "",
        phone_number: "",
        department:""

    })

    const [loadingVerificationCode, setLoadingVerificationCode] = useState(false)
    const [loadingVerificationCodeSubmit, setLoadingVerificationCodeSubmit] = useState(false)
    const [loadingRegisterSubmit, setLoadingRegisterSubmit] = useState(false)

    function handleChange(event) {
        setCredentials({ ...credentials, [event.target.name]: event.target.value?.trim() });

    }
    function languageChange(lang) {
        i18next.changeLanguage(lang)

        dispatch(changeLanguageAction(lang))
    }


    async function handleSendVerifcationCode() {
        console.log("send verification code")
        setErrorMessage("")

        setLoadingVerificationCode(true)
        setEmailSend(false)
        if (credentials.email === "") {
            setErrorMessage("Email cannot be empty")
            setLoadingVerificationCode(false)
        } else {
            setErrorMessage("")
            const createdAt = new Date().toISOString()
            await axios.post(SEND_VERFIFCATION_CODE, { email: credentials.email,createdAt:createdAt },{withCredentials:true}).then((res) => {
                console.log(res)
                setLoadingVerificationCode(false)
                setEmailSend(true)

            }).catch(err => {
                console.log(err?.response?.data)
                setErrorMessage(err?.response?.data?.message)
                setLoadingVerificationCode(false)
            })
        }


    }

    async function handleVerifcationCodeSubmit() {

        // console.log(" verification code submit")
        setLoadingVerificationCodeSubmit(true)
        setEmailVerified(false)
        setErrorMessage("")
        if (credentials.verification_code === "") {
            setErrorMessage("Verification field cannot be blank")
            setLoadingVerificationCodeSubmit(false)
        }
        else {


            axios.post(VERIFY_CODE, credentials).then(
                res => {
                    setEmailVerified(true)

                    setLoadingVerificationCodeSubmit(false)
                    dispatch(RegisterdEmailAction(credentials?.email))
                    setUserData({ ...userData, ["email"]: credentials.email })



                }
            ).catch(err => {
                console.log(err)
                setErrorMessage(err?.response?.data?.message)
                setLoadingVerificationCodeSubmit(false)
            })
        }



    }
    const handleChangeUserData = (event) => {
        const { name, value } = event.target
        // console.log(name,value)
        setUserData({ ...userData, [name]: value })
    }

    async function handleResend() {
        setLoadingVerificationCode(true)
        await axios.post(SEND_VERFIFCATION_CODE, { email: credentials.email },{withCredentials:true}).then((res) => {
            console.log(res)
            setLoadingVerificationCode(false)
            setEmailSend(true)

        }).catch(err => {
            console.log(err?.response?.data)
            setErrorMessage(err?.response?.data?.message)
            setLoadingVerificationCode(false)
        })
    }

    async function handleRegister(event) {
        event.preventDefault()


        if (document.forms[
            'register-form'
        ].reportValidity()) {
            if (userData.password !== userData.confirm_password) {
                setErrorMessageForPassword("Password and Confirm Password should be same!!")
            }
            else {
                setLoadingRegisterSubmit(true)
                setErrorMessageForPassword(false)
                const createdAt = new Date().toISOString()
                userData["createdAt"]=createdAt
                axios.post(CREATE_ACCOUNT, userData).then(res => {
                    setLoadingRegisterSubmit(false)
                    setAccountCreated(true)
                    // alert(t("Registered successfully."))
                    setTimeout(() => navigate("/sign-in"), 3000)
                    // console.log(res)
                }).catch(err => {
                    setLoadingRegisterSubmit(false)
                    console.log(err)
                })
            }
        }
    }
    return (
        <div>
        {console.log(errorMessage)}
            <section className={styles.signIn}>
                <div className={styles.features}>
                    <img src={Logo} alt="Logo" style={{ cursor: "pointer" }}
                        onClick={() => navigate("/")} className={styles.featuresLogo} />
                    <div className={styles.featureTitle}>
                        {t("Create an account now to easily find and hire the best candidates")}
                    </div>
                    <div className={styles.featureItems}>
                        <img src={Tick} alt="Tick" />
                        {t("Download, upload and screen resumes without limits.")}
                    </div>
                    <div className={styles.featureItems}>
                        <img src={Tick} alt="Tick" />
                        {t("Access seamless resume translations and tailored dashboards.")}
                    </div>
                    <div className={styles.featureItems}>
                        <img src={Tick} alt="Tick" />
                        {t("Enjoy secure resume storage and the latest feature updates.")}
                    </div>
                </div>

                {!emailSend ?
                    <div className={styles.singInCard}>
                        <div className={styles.langbtn}>
                            <span onClick={() => languageChange('ja')} style={{ cursor: "pointer" }}>日本語</span>
                            <span style={{ fontWeight: 'bolder', fontSize: "1rem" }}> | </span>
                            <span onClick={() => languageChange('en')} style={{ cursor: "pointer" }}>English</span>
                        </div>
                        {/* <img src={Logo} alt="Logo" style={{ cursor: "pointer" }}
                            onClick={() => navigate("/")} className={styles.signInLogo} /> */}
                        <div className={styles.signInTitle}>{t("Create Account")}</div>
                        {errorMessage && <p style={{ color: "red", margin: "0", padding: "0" }}>{t(errorMessage)}</p>}

                        <div>
                            <label>{t("Email address")}</label>
                            <input type="email" name="email" value={credentials.email} onChange={handleChange} />
                            <button disabled={loadingVerificationCode} className={styles.signInBtn} style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }} onClick={handleSendVerifcationCode}>{t("Register")}
                                {loadingVerificationCode && <div className="spinner" style={{ height: "15px", width: "15px" }}></div>}</button>
                        </div>

                        {/* <div className={styles.orWrapper}>
                            <div className={styles.orHR}></div>
                            <div>Or</div>
                            <div className={styles.orHR}></div>
                        </div>
                        <Link to="/sign-in">
                            <button className={styles.signInWithGoogle}>
                                {t("Sign In")}
                            </button>
                        </Link> */}
                    </div> : emailVerified ?
                        accountCreated ?
                            <div className={styles.singInCard} style={{ padding: "60px 0" }}>
                                <div className={styles.successWrapper}>
                                    <img
                                        src={SuccessGif}
                                        alt="Success"
                                        width={200}
                                        height={200}
                                    />
                                    <div className={styles.successTitle}>
                                        {t("Successfully Registered!")}
                                    </div>
                                    <div className={styles.successSubTitle}>
                                        {t("Your account has been registred successfully!")}
                                    </div>
                                </div>
                            </div> :
                            <div className={styles.singInCard} style={{margin: "1rem 0"}}>
                                <div className={styles.langbtn}>
                                    <span onClick={() => languageChange('ja')} style={{ cursor: "pointer" }}>日本語</span>
                                    <span style={{ fontWeight: 'bolder', fontSize: "1rem" }}> | </span>
                                    <span onClick={() => languageChange('en')} style={{ cursor: "pointer" }}>English</span>
                                </div>
                                {/* <img src={Logo} alt="Logo" style={{ cursor: "pointer" }}
                                onClick={() => navigate("/")} className={styles.signInLogo} /> */}
                                <div className={styles.signInTitle}>{t("Register your Account")}</div>
                                {errorMessageForPassword && <p style={{ color: "red" }}>{errorMessageForPassword}</p>}

                                <div className={styles.signInSubTitle}>
                                    <p>{t("Please enter the details to finish registration.")}</p>
                                </div>
                                <div className={styles.inputsWrapper}>
                                    <form name="register-form">
                                        <label>{t("Full Name *")}</label>
                                        <input type="text" name="full_name" required value={userData.full_name} onChange={handleChangeUserData} />
                                        <label>{t("Company Name *")}</label>
                                        <input type="text" name="company_name" required value={userData.company_name} onChange={handleChangeUserData} />
                                        <label>{t("Department")}</label>
                                        <input type="text" name="department" value={userData.department} onChange={handleChangeUserData}/>
                                        <label>{t("Job Title")}</label>
                                        <input type="text" name="job_title" required value={userData.job_title} onChange={handleChangeUserData} />
                                        <label>{t("Phone Number")}</label>
                                        <input type="text" name="phone_number" value={userData.phone_number} onChange={handleChangeUserData} />
                                        <label>{t("Password *")}</label>
                                        <input type="password" name="password" required value={userData.password} onChange={handleChangeUserData} />
                                        <label>{t("Confirm Pasword *")}</label>
                                        <input type="password" name="confirm_password" required value={userData.confirm_password} onChange={handleChangeUserData} />
                                        <button disabled={loadingRegisterSubmit} type="submit" className={styles.signInBtn} onClick={handleRegister} style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }} >{t("Create Account")}
                                            {loadingRegisterSubmit && <div className="spinner" style={{ height: "15px", width: "15px" }}></div>}
                                        </button>
                                    </form>
                                </div>
                            </div> : <div className={styles.singInCard}>
                            {/* <img src={Logo} alt="Logo" style={{ cursor: "pointer" }}
                                onClick={() => navigate("/")} className={styles.signInLogo} /> */}
                            <div className={styles.langbtn}>
                                <span onClick={() => languageChange('ja')} style={{ cursor: "pointer" }}>日本語</span>
                                <span style={{ fontWeight: 'bolder', fontSize: "1rem" }}> | </span>
                                <span onClick={() => languageChange('en')} style={{ cursor: "pointer" }}>English</span>
                            </div>

                            <div className={styles.signInTitle}>{t("Verify Email")}
                                {errorMessage && <p style={{ color: "red", fontSize: "12px", margin: "0", padding: "0" }}>{t( errorMessage )}</p>}
                            </div>


                            <div className={styles.signInSubTitle}>
                                <p>{t("We have sent an One Time Password(OTP) to your email.")}</p>
                                <p>{t("Please enter the code below to verify")} </p>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <button onClick={() => setEmailSend(false)}>{t("Change Email")}</button >
                                    <button disabled={loadingVerificationCode} onClick={handleResend} style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>{t("Resend OTP")}
                                        {loadingVerificationCode && <div className="spinner" style={{ dispaly: "flex", height: "15px", width: "15px", margin: "5px" }}></div>}</button>
                                </div>

                            </div>
                            <div>
                                <label>{t("One Time Password")}</label>
                                <input type="text" name="verification_code" value={credentials.verification_code} onChange={handleChange} />
                                <button disabled={loadingVerificationCodeSubmit} className={styles.signInBtn} style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }} onClick={handleVerifcationCodeSubmit}>{t("Verify")}
                                    {loadingVerificationCodeSubmit && <div className="spinner" style={{ dispaly: "flex", height: "15px", width: "15px", margin: "5px" }}></div>}

                                </button>
                            </div>
                        </div>}




            </section>
        </div>
    )
}