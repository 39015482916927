import styles from "./page.module.css";
import AdminNavbar from "../../components/admin-navbar/index"
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertUtcToLocal } from "../../utils/helper";
import axios from "axios";
import { ADMIN_USERS_LIST } from "../../config";

export default function Admin(props) {
  const tableRef = useRef()
  const [tableData, setTableData] = useState([]);
  const [loadingUserList, setLoadingUserList] = useState(false)
  const { t } = useTranslation()
  let columnDefs = [


    {
      field: "createdAt", headerName: t("Created At"),
      filter: "agDateColumnFilter",

      resizable: true,
      minWidth: 180,

      filterParams: {
        filterOptions: [
          "equals",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
          "inRange",
        ],
        maxNumConditions: 1,
      },

      cellRenderer: function (params) {
        return <div>{
          convertUtcToLocal(params?.value).substring(0, 17)

        }

        </div>
      },
    },
    {
      field: "full_name",
      headerName: t("Name"),
      minWidth: 150,
      filterParams: {
        maxNumConditions: 3,
      },
    },

    { field: "company_name", headerName: t("Company Name"), minWidth: 150 },
    
    { field: "department", headerName: t("Department"), minWidth: 150 },
    {
      field: "job_title",
      headerName: t("Job Title"),
      filter: "agNumberColumnFilter",
      minWidth: 120,


      filterParams: {
        filterOptions: [
          "equals",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
          "inRange",
        ],
        maxNumConditions: 1,
      },
    },

    { field: "email", headerName: t("Email"), minWidth: 150 },
    { field: "phone_number", headerName: t("Phone"), minWidth: 150 },

    { field: "verification_status", headerName: t("Status"), minWidth: 150 },
  ];
  const defaultColDef = useMemo(() => {
    return {

      sortable: true,
      filter: true,
      flex: 1,
      suppressMovable: true,
      // floatingFilter: true,
      filterParams: {
        buttons: ["reset"],
        filterOptions: ["startsWith", "endsWith", "contains"],
      },
    };
  }, []);
  useEffect(() => {
    setLoadingUserList(true)
    axios.get(ADMIN_USERS_LIST,{withCredentials:true}).then(res => {
      console.log(res)
      setTableData(res?.data)
      setLoadingUserList(false)
    }).catch(err => {
      console.log(err)
      setLoadingUserList(false)
    })
  }, [])
  return (
    <div>
      <AdminNavbar handleLogoutAdmin={props.handleLogoutAdmin}>
        <div style={{ margin: "1rem" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px" }}>
            {/* <h1>List of Users</h1> */}
            {loadingUserList && <div className="spinner" style={{ height: "25px", width: "25px" }}></div>}

          </div>
          <div className="">
            <div className="ag-theme-alpine">
              <AgGridReact
                ref={tableRef}
                rowData={tableData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                pagination={true}
                paginationPageSize={10}
              />

            </div>
          </div>
        </div>
      </AdminNavbar>

    </div>
  )
}