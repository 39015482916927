import Logo from "../../static/images/logo.svg"
import Globe from "../../static/images/globe.svg"
import PrivacyOne from "../../static/privacy-policy/p1.pdf"
import PrivacyTwo from "../../static/privacy-policy/p2.pdf"

import styles from "./page.module.css";
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeLanguageAction } from '../../redux/actions';

export default function PrivacyPolicy() {
    const { t } = useTranslation()
    const [language, setLanguage] = useState("日本語")
    const dispatch = useDispatch()
    const navigate=useNavigate()
    // const blob = new Blob([PrivacyOne], { type: 'application/pdf' });
    // const [privacy_one,set_privacy_one] = useState(URL.createObjectURL(blob))
    
    function languageChange() {

        if (language === "日本語") {
            console.log("Japnese clicked")

            setLanguage("English")
            i18next.changeLanguage("en")
            dispatch(changeLanguageAction("en"))
        } else {
            console.log("English clicked")
            setLanguage("日本語")
            i18next.changeLanguage("ja")
            dispatch(changeLanguageAction("ja"))
        }
    }

    return (
        <section>
            <div className={styles.navbar}>
                <img style={{cursor:"pointer"}} src={Logo} onClick={()=>navigate("/")} alt="logo" width={75} height={47} />
                <div className={styles.navLinks}>

                </div>
                <div className={styles.LangAndSignInWrapper}>
                    <div onClick={languageChange} className={styles.dropdown}>
                        <button className={styles.dropbtn}>
                            <img src={Globe} alt="globe" />
                            {language === "日本語" ? <span>English</span> : <span>日本語</span>}
                        </button>
                    </div>
                    <Link to="/sign-in">
                        <button className={styles.getStartedBtn}> {t("Get Started")} </button>
                    </Link>
                </div>
            </div>

            {/* <div className={styles.heading}>
                {t("Privacy Policy")}
            </div> */}
            <div className={styles.heading}>
                {t("Resume Sort利用規約")}
            </div>
            <embed src="https://cosbefonts.s3.amazonaws.com/p1.pdf" width="100%" height="850px" type="application/pdf"></embed>
            <div className={styles.heading} style={{ marginTop: '135px' }}>
                {t("Resume Sortプライバシーポリシー")}
            </div>
            <embed src="https://cosbefonts.s3.amazonaws.com/p2.pdf" width="100%" height="850px" type="application/pdf"></embed>

            <div className={styles.footer}>
                <div className={styles.footerImg}>
                    <img src={Logo} alt="logo" width={116} height={73} />
                </div>
                <div className={styles.footerContent}>
                    <div>
                        <div className={styles.footerContentTitle}>products</div>
                        <Link to={'https://www.v2.resume-sort-app.cosbe.inc/'}>Resume Screening</Link>
                        <Link to="https://www.cosbe.inc/call-cosbe">Call CosBE</Link>
                    </div>
                    <div>
                        <div className={styles.footerContentTitle}>Services</div>
                        <Link to="https://www.cosbe.inc/solutions">CosBE Pro-Service</Link>
                        <Link to="https://www.cosbe.inc/call-cosbe">CosBE SaaS</Link>
                        <Link to="https://cosbetrialschool-english.my.canva.site/">CosBE School</Link>
                    </div>
                    <div>
                        <div className={styles.footerContentTitle}>Info</div>
                        <Link to="https://www.cosbe.inc/about">About CosBE</Link>
                        <Link to="https://ws.formzu.net/fgen/S208480895/">Contact Us</Link>
                    </div>
                    <div>
                        <div className={styles.footerContentTitle}>Terms</div>
                        <Link to="">Privacy Policy</Link>
                        {/* <Link to="">Terms & Conditions</Link> */}
                    </div>
                </div>
            </div>
            <div className={styles.footerFooter}>
                <div className={styles.dropdown}>
                    <div onClick={languageChange} className={styles.dropdown}>
                        <button className={styles.dropbtn}>
                            <img src={Globe} alt="globe" />
                            {language === "日本語" ? <span>English</span> : <span>日本語</span>}
                        </button>

                    </div>

                </div>
                <div className={styles.copyright}>Copyright © {new Date().getFullYear()} CosBE</div>
            </div>
        </section>
    )
}