export const LoginEmailAction = (value) => {
    return {
        type: 'LoginEmail',
        payload: value,
    }
}
export const RegisterdEmailAction = (value) => {
    return {
        type: 'RegisterdEmail',
        payload: value,
    }
}

export const UserIdAction = (value) => {
    return {
        type: 'UserID',
        payload: value,
    }
}

export const projectDetailsAction = (value) => {
    return {
        type: 'ProjectDetails',
        payload: value,
    }
}
export const profileDetailsAction = (value) => {
    return {
        type: 'ProfileDetails',
        payload: value,
    }
}

export const changeLanguageAction = (value) => {
    return {
        type: 'LANGUAGE_CHANGE',
        payload: value,
    }
}