import styles from "./page.module.css";

import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { changeLanguageAction } from "../../redux/actions";
import { useRef, useState } from "react";

import Logo from "../../static/images/logo.svg";
import Tick from "../../static/images/feature-tick.svg";
import { ADMIN_SIGN_IN } from "../../config";
import axios from "axios";

export default function AdminSignIn(props) {
    let navigate = useNavigate()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [credentials, setCredentials] = useState({ email: "", password: "" })
    const [loadingLogin, setLoadingLogin] = useState(false)
    function handleChange(event) {
        setCredentials({ ...credentials, [event.target.name]: event.target.value });

    }
    function handleLoginSubmit() {

        if (credentials.email === "" || credentials.password === "") {
            alert(t("Email or Password can't be empty"))
        }
        else {
            setLoadingLogin(true)
            axios.post(ADMIN_SIGN_IN, credentials, {

                withCredentials: true
            })
                .then((res) => {
                    console.log(res)
                  
                    setLoadingLogin(false)
                    props.setAuthenticatedAdmin(true)
                    navigate("/admin/users");

                }).catch(err => {
                    console.log(err)
                    setLoadingLogin(false)
                    alert(t(err?.response?.data?.detail))
                    props.setAuthenticatedAdmin(false)


                })
        }
    }

    function languageChange(lang) {
        i18next.changeLanguage(lang)

        dispatch(changeLanguageAction(lang))
    }





    return (
        <div>
            <section className={styles.signIn}>
                <div className={styles.features}>
                    <img src={Logo} alt="Logo" style={{ cursor: "pointer" }}
                        onClick={() => navigate("/")} className={styles.featuresLogo} />
                    <div className={styles.featureTitle}>
                        {t("Admin Page")}
                    </div>
                </div>

                {/* {getEmail ? <GetEmailCard /> : register ? <RegistrationDetails /> : <VerifyEmail />} */}
                <div className={styles.singInCard}>
                    <div className={styles.langbtn}>
                        <span onClick={() => languageChange('ja')} style={{ cursor: "pointer" }}>日本語</span>
                        <span style={{ fontWeight: 'bolder', fontSize: "1rem" }}> | </span>
                        <span onClick={() => languageChange('en')} style={{ cursor: "pointer" }}>English</span>
                    </div>


                    <div>
                        <label>{t("Email address")}</label>
                        <input type="email" name="email" value={credentials.email}
                            onChange={handleChange} />

                        <label>{t("Password")}</label>
                        <input type="password" name="password" value={credentials.password}
                            onChange={handleChange} />

                        <button disabled={loadingLogin} className={styles.signInBtn} style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }} onClick={handleLoginSubmit}>{t("Login")}
                            {loadingLogin && <div className="spinner" style={{ dispaly: "flex", height: "15px", width: "15px", margin: "5px" }}></div>}

                        </button>

                    </div>
                </div>


            </section>
        </div>
    )
}