import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/navbar/index"
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { DELETE_RESUME_DATA, GET_RESUME_DATA } from "../../config";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { profileDetailsAction } from "../../redux/actions";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import LoadingBar from "react-top-loading-bar";
import { convertUtcToLocal } from "../../utils/helper";
// import "ag-grid-community/styles/ag-grid.css"; // Core CSS
// import "ag-grid-community/styles/ag-theme-quartz.css"; 
function getResumeData(projectID) {
  return axios
    .get(`${GET_RESUME_DATA}?projectID=${projectID}`,{withCredentials:true})
    .then((res) => res.data);
}
export default function History(props) {
  const UserIdReduxState = useSelector(state => state.UserIdReduxState)
  const dispatch = useDispatch()
  const tableRef = useRef()
  const navigate = useNavigate()
  const loadingRef = useRef(null)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation()
  let [options, setOptions] = useState([
    { value: "name", label: 'Name', checked: false },
    { value: "email", label: 'Email', checked: false },
    { value: "phone_number", label: 'Phone', checked: false },
    { value: "year_of_experience", label: 'Experience(years)', checked: false },
    { value: "role", label: 'Role', checked: false },
    { value: "contacts", label: 'Address', checked: false },
    { value: "summary", label: 'Summary', checked: false },
    { value: "education", label: 'Education', checked: false },
    { value: "work_experience", label: 'Work Experience', checked: false },
    { value: "certifications", label: 'Certifications', checked: false },
    { value: "skills", label: 'Skills', checked: false },
    { value: "languages", label: 'Languages', checked: false },
    { value: "references", label: 'References', checked: false },
    { value: "projects", label: 'Projects', checked: false },
  ]);
  let columnDefs = [


    {
      field: "datetime", headerName: t("Uploaded At"),
      filter: "agDateColumnFilter",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      resizable: true,
      minWidth: 180,

      filterParams: {
        filterOptions: [
          "equals",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
          "inRange",
        ],
        maxNumConditions: 1,
      },

      cellRenderer: function (params) {
        return <div>{
          convertUtcToLocal(params?.value).substring(0, 17)

        }

        </div>
      },
    },
    {
      field: "extracted_json_data.person_name",
      headerName: t("Name"),
      minWidth: 150,
      filterParams: {
        maxNumConditions: 3,
      },
    },

    { field: "extracted_json_data.job_title", headerName: t("Role"), minWidth: 150 },
    {
      field: "extracted_json_data.years_of_work_experience",
      headerName: t("Experience(years)"),
      filter: "agNumberColumnFilter",
      minWidth: 120,

      cellRenderer: function (params) {
        return (
          <div>
            {params?.value}

          </div>

        );
      },
      filterParams: {
        filterOptions: [
          "equals",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
          "inRange",
        ],
        maxNumConditions: 1,
      },
    },
    {
      field: "extracted_json_data.skills",
      headerName: t("Skills"),
      minWidth: 150,
      filterParams: {
        filterOptions: ["contains", "notContains", "notBlank", "blank"],
        maxNumConditions: 10,
      },
    },
    { field: "extracted_json_data.email", headerName: t("Email"), minWidth: 150 },
    { field: "extracted_json_data.phone_number", headerName: t("Phone"), minWidth: 150 },
    {
      field: "pdf_url",
      headerName: t("Pdf"),
      filter: false,
      minWidth: 100,
      cellRenderer: function (params) {
        return (
          <>
            {/* {console.log(params)}  */}
            <p style={{ color: "blue", fontWeight: "bold", cursor: "pointer" }} onClick={(e) => {
              e.stopPropagation()
              dispatch(profileDetailsAction(params.data))
              navigate("/jis", { state: { pdf_path: params.value.split("s3.amazonaws.com/")[1] } })
            }}>JIS Format</p>
          </>

        );
      },
    },
  ];
  const defaultColDef = useMemo(() => {
    return {

      sortable: true,
      filter: true,
      flex: 1,
      suppressMovable: true,
      // floatingFilter: true,
      filterParams: {
        buttons: ["reset"],
        filterOptions: ["startsWith", "endsWith", "contains"],
      },
    };
  }, []);
  const handleCheckboxChange = (value) => {
    setOptions(options.map(option =>
      option.value === value ? { ...option, checked: !option.checked } : option
    ));
  };
  function handleCSVDownload() {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.csv';
    const fileName = "resume_data"
    let csv_data = []
    tableData.forEach(eachData => {
      const { name, role, year_of_experience, email, phone_number, ...rest } = eachData.extracted_json_data
      csv_data.push({ name, role, year_of_experience, email, phone_number, ...rest })
    })
    let selected_field = options.filter(option => option.checked === true)

    let filtered_data = []
    if (selected_field.length === 0) {
      filtered_data = csv_data
    } else {
      filtered_data = csv_data.map(entry => {
        let filteredEntry = {};
        selected_field.forEach(field => {
          if (entry.hasOwnProperty(field.value)) {
            filteredEntry[field.value] = entry[field.value];
          }
        });
        return filteredEntry;
      });
    }


    const ws = XLSX.utils.json_to_sheet(filtered_data);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  useEffect(() => {
    loadingRef.current.continuousStart();
    // console.log(ProjectDetailsReduxState?._id)
    getResumeData(UserIdReduxState)
      .then((res) => {
        console.log(res.data);
        setTableData(res?.data);
        loadingRef.current.complete();
      })
      .catch((err) => {
        console.log(err);
        loadingRef.current.complete();
      });
  }, []);


  const [selectedRows, setSelectedRows] = useState([]);


  const onSelectionChanged = useCallback(() => {
    const rows = tableRef.current.api.getSelectedRows();

    setSelectedRows(rows)
  }, []);

  const removeElements = (_ids) => {
    const updatedArray = tableData.filter(each => !_ids.includes(each?._id))
    setTableData(updatedArray)
  }
  const deleteSelectedRows = () => {
    console.log(selectedRows)
    setDeleteLoading(true)
    const formData = new FormData()
    selectedRows.forEach(each => {
      formData.append("ids", each._id)
    })

    const resumes_ids = selectedRows.map(eachData => eachData._id)

    if (selectedRows.length > 0) {

      console.log(resumes_ids)
      axios({
        method: "POST",
        url: DELETE_RESUME_DATA,
        data: formData,
        withCredentials: true
      }).then(res => {
        setDeleteLoading(false)
        removeElements(resumes_ids)
      }).catch(err => {
        console.log(err)

        setDeleteLoading(false)
      })

    }
    else {
      alert("You have nothing to delete")
    }

  };
  return (
    <>

      <LoadingBar color="#0d6efd" ref={loadingRef} />
      <Navbar handleLogout={props.handleLogout}>
        <div className="wrapper-ater-log-in">
          <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row-reverse" }}>
            <button style={{ display: "flex", cursor: "pointer", justifyContent: "center", alignItems: "center", gap: "10px" }} className="delete-btn" disabled={selectedRows.length === 0} onClick={deleteSelectedRows}>{t("Delete Resume")}{deleteLoading && <div className="spinner" style={{ dispaly: "flex", height: "15px", width: "15px", margin: "5px" }}></div>}</button>
            <button style={{ cursor: "pointer" }} onClick={() => {
              // console.log("set filter to null")
              tableRef.current.api.setFilterModel({})
              alert("All filters removed")
            }
            } className="clear-filter-btn">
              {t("Clear All Filters")}
            </button>
          </div>

          <div className="">
            <div className="ag-theme-alpine">
              <AgGridReact
                ref={tableRef}
                rowData={tableData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}

                rowSelection="multiple"
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                domLayout="autoHeight"
                pagination={true}
                paginationPageSize={10}
              // rowHeight={40}
              />

            </div>
          </div>
        </div>
      </Navbar>
    </>
  );
}
