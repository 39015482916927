



import styles from "./comp.module.css";

import Globe from "../../static/images/globe.svg"
import Language from "../../static/images/language.svg";

import Arrow from "../../static/images/arrow-down.svg";
import Logo from "../../static/images/logo.svg";
import Cloud from "../../static/images/upload.svg";
import History from "../../static/images/history.svg";

import axios from "axios";
import { LOGOUT_URL } from "../../config";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { changeLanguageAction, profileDetailsAction } from "../../redux/actions";
import { useTranslation } from "react-i18next";


export default function Navbar(props) {
  const [sidebar, setSideBar] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [language, setLanguage] = useState("日本語")
  function languageChange(lang) {
    i18next.changeLanguage(lang)
    dispatch(changeLanguageAction(lang))
    if (lang === "ja") {
      console.log("Japnese clicked")
      setLanguage("日本語")
    } else {
      console.log("English clicked")
      setLanguage("English")
    }
  }

  return (

    <div className={styles.navigationWrapper}>
      <div className={styles.sidebar}>
        <img
          src={Logo}
          alt="Logo"
          width={75}
          height={48}
          className={styles.logo}
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/")
            dispatch(profileDetailsAction(""))

          }}
        />
        <Link to={"/upload"} style={{ textDecoration: "none", width: "fit-content" }}>
          <button onClick={() => {
            dispatch(profileDetailsAction(""))
          }}>
            <img
              src={Cloud}
              alt="cloud"
              style={{ marginRight: "10px" }}
              width={25}
              height={20}
            />
            {t("Upload Resume")}
          </button>
        </Link>
        <Link to={"/screened-resume"} style={{ textDecoration: "none", width: "fit-content" }}>
          <button onClick={() => {
            dispatch(profileDetailsAction(""))
          }}>
            <img
              src={History}
              alt="history"
              style={{ marginRight: "10px" }}
              width={25}
              height={20}
            />
            {t("Screened Resumes")}
          </button>
        </Link>
      </div>

      <div style={{ width: "100%" }}>
        <div className={styles.navbar}>
          <div className={styles.hamburger} onClick={() => sidebar ? setSideBar(false) : setSideBar(true)}>
            <div className={styles.hamburgerDivs}></div>
            <div className={styles.hamburgerDivs}></div>
            <div className={styles.hamburgerDivs}></div>

            {sidebar ?
              <div>
                <div className={styles.pageNavBtn}>
                  <Link to={"/upload"} style={{ textDecoration: "none", width: "fit-content" }}>
                    <button onClick={() => {
                      dispatch(profileDetailsAction(""))
                    }}>
                      <img
                        src={Cloud}
                        alt="cloud"
                        style={{ marginRight: "10px" }}
                        width={25}
                        height={20}
                      />
                      {t("Upload Resume")}
                    </button>
                  </Link>
                  <Link to={"/screened-resume"} style={{ textDecoration: "none", width: "fit-content" }}>
                    <button onClick={() => {
                      dispatch(profileDetailsAction(""))
                    }}>
                      <img
                        src={History}
                        alt="history"
                        style={{ marginRight: "10px" }}
                        width={25}
                        height={20}
                      />
                      {t("Screened Resumes")}
                    </button>
                  </Link>
                </div>
                <div className={styles.langAndSignOutBtn}>
                  <div className={styles.dropdown}>
                    <button className={styles.dropbtn}>
                      <img src={Globe} alt="globe" />
                      {language === "日本語" ? "English" : "日本語"}
                      <img src={Arrow} alt="arrow" />
                    </button>
                    <div className={styles.dropdownContent}>
                      <button onClick={() => languageChange('ja')}>日本語</button>
                      <button onClick={() => languageChange('en')}>English</button>
                    </div>
                  </div>
                  <div>
                    <button className={styles.signOut} onClick={() => props.handleLogout()}>{t('Sign Out')}</button>
                  </div>
                </div>
              </div> :
              null
            }
          </div>



          <div className={styles.navRight}>
            <div className={styles.dropdown}>
              <button className={styles.dropbtn}>
                <img src={Globe} alt="globe" />
                {language === "日本語" ? "English" : "日本語"}
                <img src={Arrow} alt="arrow" />
              </button>
              <div className={styles.dropdownContent}>
                <button onClick={() => languageChange('ja')}>日本語</button>
                <button onClick={() => languageChange('en')}>English</button>
              </div>
            </div>
            <div>
              <button className={styles.signOut} onClick={() => props.handleLogout()}>{t('Sign Out')}</button>
            </div>
          </div>
        </div>
        {props?.children}
      </div>
    </div>
  );
}
