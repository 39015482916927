
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import { ADMIN_LOGOUT_URL, LOGOUT_URL } from './config';
import axios from 'axios';
import decodeCookie from "./utils/decodeCookie";
import Upload from "./pages/upload/page"
import SignIn from "./pages/sign-in/page"
// import CreateAccount from "./pages/create-account/page"
import JIS from "./pages/jis-format/page"
import "./globals.css"
import "./prod_fonts.css"
import LandingPage from "./pages/landing-page/page";
import ScreenedResume from "./pages/screened-resume/page";
import LoadingBar from "react-top-loading-bar";
import { useDispatch } from "react-redux";
import { LoginEmailAction, UserIdAction, profileDetailsAction } from "./redux/actions";
import CreateAccount from "./pages/create-account/page";
import AdminSignIn from "./pages/admin-sign-in/page"
import AdminUsers from "./pages/admin-users/page"
import AdminFeedback from "./pages/admin-feedback/page"
import PrivacyPolicy from "./pages/privacy-policy/page"
import decodeCookieAdmin from "./utils/decodeCookieAdmin";


function App() {
  const loadingRef = useRef(null)
  const dispatch = useDispatch()
  const [authenticated, setAuthenticated] = useState(!!decodeCookie())
  const [authenticatedAdmin, setAuthenticatedAdmin] = useState(!!decodeCookieAdmin())




  function handleLogout() {
    loadingRef.current.continuousStart();
    axios.get(LOGOUT_URL, { withCredentials: true }).then(res => {
      console.log(res.data)
      loadingRef.current.complete()
      dispatch(profileDetailsAction(""))
      dispatch(UserIdAction(""))
      dispatch(LoginEmailAction(""))
      setAuthenticated(false)

    }).catch(err => {
      console.log(err)
      loadingRef.current.complete()
    })
  }
  function handleLogoutAdmin() {
    loadingRef.current.continuousStart();
    axios.get(ADMIN_LOGOUT_URL, { withCredentials: true }).then(res => {
      setAuthenticatedAdmin(false)
      loadingRef.current.complete()
    }).catch(err => {
      console.log(err)
      loadingRef.current.complete()
    })
  }
  useEffect(() => {
    const decodedToken = decodeCookie();
    if (decodedToken) {
      setAuthenticated(true);
    }
    const decodedTokenAdmin = decodeCookieAdmin();
    if (decodedTokenAdmin) {
      setAuthenticatedAdmin(true);
    }
  }, []);
  return (
  
    <div className="App">
    {console.log("authenticated===>",authenticatedAdmin)}
      <LoadingBar color="#0d6efd" ref={loadingRef} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/admin/sign-in" element={
            authenticatedAdmin?
            <Navigate to="/admin/users"/>:
            (
              <AdminSignIn setAuthenticatedAdmin={setAuthenticatedAdmin}/>
            )
          } />
          <Route path="/admin/users" element={

            authenticatedAdmin ? (
              <AdminUsers handleLogoutAdmin={handleLogoutAdmin} />

            ) : (
              <Navigate to="/admin/sign-in" />
            )
          }

          />
          <Route path="/admin/feedback" element={authenticatedAdmin ? (
            <AdminFeedback handleLogoutAdmin={handleLogoutAdmin} />

          ) : (
            <Navigate to="/admin/sign-in" />
          )} />
          <Route path="/sign-in" element={
            authenticated ? (
              <Navigate to="/upload" />
            ) : (
              <SignIn setAuthenticated={setAuthenticated} />
            )
          } />
          <Route path="/upload" element={
            authenticated ? (
              <Upload handleLogout={handleLogout} />
            ) : (
              <Navigate to="/sign-in" />
            )
          } />
          <Route path="/jis" element={
            authenticated ? (
              <JIS handleLogout={handleLogout} />
            ) : (
              <Navigate to="/sign-in" />
            )
          } />

          <Route path="/screened-resume" element={
            authenticated ? (
              <ScreenedResume handleLogout={handleLogout} />
            ) : (
              <Navigate to="/sign-in" />
            )
          } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
